<template>
    <div class="view-wrapper has-top-nav">
        <Preloader v-if="isLoading" />

        <div class="page-content-wrapper">
            <div class="page-content">
                <div class="section-title">
                    <h2>Submitted Project List</h2>
                    <h4 v-if="active_tab==='all'">In total, {{ shownUsers.length }} users have submitted projects.</h4>
                    <h4 v-if="active_tab==='projects'">In total, {{ shownProjects.length }} submitted projects.</h4>
                </div>
                <div>
                    <div class="list-view-toolbar">
                        <div class="tabs-inner">
                            <div class="tabs">
                                <ul>
                                    <li id="tab-all" class="is-active" @click="setActiveTab('all')">
                                        <a  role="button" >
                                            <span>User</span>
                                        </a>
                                    </li>
                                    <li id="tab-projects" @click="setActiveTab('projects')">
                                        <a role="button">
                                            <span>Project</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="control has-icon is-flex">
                            <div>
                                <input type="text" class="input" placeholder="Search..." v-model="searchQuery">
                                <iconify-icon class="form-icon iconify" icon="lucide:search" />
                            </div>
                            <div class="toolbar" v-if="active_tab === 'all'">
                                <div 
                                    class="select-dropdown" 
                                >
                                    <div class="sort-buttons" @click="changeSortOrder()">
                                        <iconify-icon class="iconify" icon="octicon:sort-asc-16" v-if="sortOrder==='asc'"/>
                                        <iconify-icon class="iconify" icon="octicon:sort-desc-16" v-if="sortOrder==='desc'"/>
                                    </div>
                                    <select v-model="sortCriteria" class="select-menu">
                                        <option value="companyName">Company Name</option>
                                        <option value="email">Email</option>
                                        <option value="name">Name</option>
                                        <option value="credits">Credits</option>
                                        <option value="uid">UID</option>
                                    </select>
                                </div>
                            </div>
                            <div class="toolbar" v-if="active_tab === 'projects'">
                                <div 
                                    class="select-dropdown" 
                                >
                                    <div class="sort-buttons" @click="changeSortOrder()">
                                        <iconify-icon class="iconify" icon="octicon:sort-asc-16" v-if="sortOrder==='asc'"/>
                                        <iconify-icon class="iconify" icon="octicon:sort-desc-16" v-if="sortOrder==='desc'"/>
                                    </div>
                                    <select v-model="sortCriteriaProjects" class="select-menu">
                                        <option value="companyName">Company Name</option>
                                        <option value="submissionName">Submission Account Name</option>
                                        <option value="submissionTime">Submitted Time</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="buttons" v-if="active_tab === 'all'">
                            <button class="button v-button is-primary is-raised" @click.prevent="backup()">Export</button>
                        </div> -->
                    </div>
                    <div class="list-view" v-if="active_tab==='all'">
                        <div class="list-view-inner">
                            <div class="list-view-item" v-for="(user, index) in shownUsers" :key="user.uid">
                                <div class="list-view-item-inner">
                                        <div class="pre-meta">
                                            <h3>{{ user.submissionAccountCompanyName }}</h3>
                                            <h4>{{ user.submissionAccountEmail }}</h4>
                                        </div>
                                        <div class="meta-left">
                                            <h3>{{ user.submissionAccountName }}</h3>
                                            <span>UID: <b>{{ user.uid }}</b></span>
                                        </div>
                                        <div class="meta-right">
                                            <div class="info-list">
                                                <span>Total submissions: <b>{{ user.numberOfSubmissions }}</b></span>
                                                <span>Credits Left: <b>{{ user.submissionAccountCredits }}</b></span>
                                            </div>
                                            <div class="buttons" v-if="selectUserID !== user.uid">
                                                <!-- <button class="button v-button is-light is-rounded" @click="selectUserID=user.uid">Change Credits</button> -->
                                                <a class="button v-button is-primary is-raised" @click="viewAllProjects(user.uid)">View All Projects</a>
                                            </div>

                                            <!-- <div class="credits_changer" v-else>
                                                <section>
                                                    <input type="number" v-model="credits_change" placeholder="Enter number of credits" class="input">
                                                </section>
                                                <footer>
                                                    <button class="button v-button is-primary is-raised" @click="creditChanger(user, 'add', credits_change)">Add</button>
                                                    <button class="button v-button is-light is-raised is-bold" @click="creditChanger(user, 'remove', credits_change)">Remove</button>
                                                    <button class="button v-button is-raised" @click="selectUserID = null">Cancel</button>
                                                </footer>
                                            </div> -->
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-view" v-if="active_tab==='projects'">
                        <div class="list-view-inner">
                            <div class="list-view-item" v-for="(project, index) in shownProjects" :key="index">
                                <div class="list-view-item-inner">
                                        <div class="pre-meta">
                                            <h3>{{ project.projectName }}</h3>
                                            <span><b>Service:</b> {{ project.category }}</span>
                                            <span><b>Data Type:</b> {{ project.type }}</span>
                                            <span><b>Status:</b> {{ project.status }}</span>
                                        </div>
                                        <div class="meta-left">
                                            <h3>Sample ID: {{ project.sampleID }}</h3>
                                            <span>Subject Name: {{ project.firstname }}  {{ project.lastname }}</span>
                                            <span>Subject Gender: {{ project.sex }}</span>
                                            <span>Subject Date of Birth: {{ project.dateOfBirth }}</span>
                                            <span>Subject Country: {{ project.country }}</span>
                                        </div>
                                        <div class="meta-right">
                                            <div class="info-list" style="align-items: flex-start;">
                                                <span><b>Submitted Company:</b> {{ project.submissionAccountCompanyName }}</span>
                                                <span><b>Submitted Account:</b>{{ project.submissionAccountName }}</span>
                                                <span><b>Submitted Time:</b> {{ project.timestamp }}</span>
                                            </div>
                                            <!-- <div class="buttons" v-if="selectUserID !== user.uid">
                                                <a class="button v-button is-primary is-raised" @click="viewDetailedProfile(user)">View All Projects</a>
                                            </div> -->

                                            <!-- <div class="credits_changer" v-else>
                                                <section>
                                                    <input type="number" v-model="credits_change" placeholder="Enter number of credits" class="input">
                                                </section>
                                                <footer>
                                                    <button class="button v-button is-primary is-raised" @click="creditChanger(user, 'add', credits_change)">Add</button>
                                                    <button class="button v-button is-light is-raised is-bold" @click="creditChanger(user, 'remove', credits_change)">Remove</button>
                                                    <button class="button v-button is-raised" @click="selectUserID = null">Cancel</button>
                                                </footer>
                                            </div> -->
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="detail-view" v-else>
                        <VProfile :user="currentUserDetail" :key="currentUserDetail.uid"/>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from "vue";
import { db, storage } from "@/firebase";
import { doc, getDocs, collection, updateDoc, writeBatch } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL  } from "firebase/storage";
import VProfile from "../profile/VProfile.vue";

export default {
    name: "UserInfoArea",
    data() {
        return {
            active_tab: reactive('all'),
            users: reactive([]),
            sortDropdownActive: reactive(false),
            sortOrder: reactive('desc'),
            searchQuery: '',
            sortCriteria: 'companyName',
            sortCriteriaProjects: 'submissionTime',
            currentUserDetail: null,
            cachedShownUsers: reactive([]),
            projects: reactive([]),
            cachedShownProjects: reactive([]),
        };
    },
    components: {
        VProfile
    },
    computed: {
        shownUsers() {
            return this.cachedShownUsers;
        },
        shownProjects() {
            return this.cachedShownProjects;
        },
    },
    watch: {
        users: 'updateShown',
        projects: 'updateShown',
        searchQuery: 'updateShown',
        sortOrder: 'updateShown',
        sortCriteria: 'updateShown',
    },
    methods: {
        updateShown() {
            if (this.active_tab === 'all') {
                this.updateShownUsers();
            } else if (this.active_tab === 'projects') {
                this.updateShownProjects();
            }
        },

        updateShownUsers(){
            let filteredUsers;
            if (!this.searchQuery) {
                filteredUsers = this.users;
            }
            else {
                const lowSearchQuery = this.searchQuery.toLowerCase();
                filteredUsers = this.users.filter((user) => {
                    if (user.submissionAccountCompanyName.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (user.submissionAccountEmail.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (user.submissionAccountName && user.submissionAccountName.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (user.uid.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    return false;
                });
            }

            let sortedUsers;
            if (this.sortCriteria === 'companyName' || this.sortCriteria === 'email' || this.sortCriteria === 'name' || this.sortCriteria === 'uid') {
                let thisCriteria;
                if (this.sortCriteria === 'companyName') {
                    thisCriteria = 'submissionAccountCompanyName';
                }
                else if (this.sortCriteria === 'email') {
                    thisCriteria = 'submissionAccountEmail';
                }
                else if (this.sortCriteria === 'name') {
                    thisCriteria = 'submissionAccountName';
                }
                else if (this.sortCriteria === 'uid') {
                    thisCriteria = 'uid';
                }
                sortedUsers = filteredUsers.sort((a, b) => {
                    return a[thisCriteria].localeCompare(b[thisCriteria]);
                });
            } else if (this.sortCriteria === 'credits') {
                sortedUsers = filteredUsers.sort((a, b) => {
                    return a.submissionAccountCredits - b.submissionAccountCredits;
                });
            } else {
                console.error('Invalid sort criteria:', this.sortCriteria);
                this.sortCriteria = 'companyName';
            }
            
            if (this.sortOrder === 'asc') {
                this.cachedShownUsers = sortedUsers;
            } else {
                this.cachedShownUsers = sortedUsers.reverse();
            }
        },

        updateShownProjects(){
            let filteredProjects;
            if (!this.searchQuery) {
                filteredProjects = this.projects;
            }
            else {
                const lowSearchQuery = this.searchQuery.toLowerCase();
                filteredProjects = this.projects.filter((project) => {
                    if (project.uid.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.submissionAccountCompanyName.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.submissionAccountName && project.submissionAccountName.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.firstname && project.firstname.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.lastname && project.lastname.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.projectName && project.projectName.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.sampleID && project.sampleID.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.status && project.status.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.sex && project.sex.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.dateOfBirth && project.dateOfBirth.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    if (project.country && project.country.toLowerCase().includes(lowSearchQuery)) {
                        return true;
                    }
                    return false;
                });
            }
            console.log('Filtered projects:', filteredProjects);
            let sortedProjects;
            if (this.sortCriteriaProjects === 'companyName' || this.sortCriteriaProjects === 'name') {
                let thisCriteria;
                if (this.sortCriteriaProjects === 'companyName') {
                    thisCriteria = 'submissionAccountCompanyName';
                }
                else if (this.sortCriteriaProjects === 'name') {
                    thisCriteria = 'submissionAccountName';
                }
                sortedProjects = filteredProjects.sort((a, b) => {
                    return a[thisCriteria].localeCompare(b[thisCriteria]);
                });
            } else if (this.sortCriteriaProjects === 'submissionTime') {
                sortedProjects = filteredProjects.sort((a, b) => {
                    return a.timestamp.localeCompare(b.timestamp);
                });
            } else {
                console.error('Invalid sort criteria:', this.sortCriteriaProjects);
                this.sortCriteriaProjects = 'submissionTime';
            }
            
            if (this.sortOrder === 'asc') {
                this.cachedShownProjects = sortedProjects;
            } else {
                this.cachedShownProjects = sortedProjects.reverse();
            }
        },

        setActiveTab(tab, user=null) {
            this.active_tab = tab;
            const tabs = document.querySelectorAll('.tabs ul li');
            tabs.forEach((tab) => {
                tab.classList.remove('is-active');
            });
            document.getElementById(`tab-${tab}`).classList.add('is-active');
            if (tab === 'all' || tab === 'projects') {
                this.searchQuery = '';
                this.getAllProjects();
            }
            else if (tab === 'details') {
                if (user) {
                    this.currentUserDetail = user;
                }
                else {
                    this.currentUserDetail = this.shownUsers[0];
                }
            }
        },

        async getAllProjects(collectionPath = 'users') {
            try {
                const querySnapshot = await getDocs(collection(db, collectionPath));
                const userPromises = [];
                this.projects = [];
                this.users = [];
                const cachedProjects = [];
                const cachedUsers = [];
                
                querySnapshot.forEach((doc) => {
                    const user = doc.data();
                    const userInfo = {
                        uid: user.uid,
                        submissionAccountEmail: user.email,
                        submissionAccountName: user.firstname + ' ' + user.lastname,
                        submissionAccountCompanyName: user.companyName,
                        submissionAccountCredits: user.credits,
                    };
                    
                    // Fetch submissions for each user
                    const submissionRef = collection(db, 'users', user.uid, 'submissions');
                    const submissionPromise = getDocs(submissionRef).then((submissionSnapshot) => {
                        if (!submissionSnapshot.empty) {
                            submissionSnapshot.forEach((submissionDoc) => {
                                const submissionInfo = submissionDoc.data();
                                if (submissionInfo.timestamp) {
                                    submissionInfo.timestamp = submissionInfo.timestamp.toDate().toLocaleString();
                                }
                                cachedProjects.push({
                                    ...userInfo,
                                    ...submissionInfo,
                                });
                            });
                            userInfo.numberOfSubmissions = submissionSnapshot.size;
                            cachedUsers.push(userInfo); // Push user info only once
                        }
                    });
                    userPromises.push(submissionPromise);
                });

                // Wait for all user submissions to be fetched
                await Promise.all(userPromises);
                this.projects = cachedProjects;
                this.users = cachedUsers;
                console.log('Projects:', this.projects[0]);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        },

        
        changeSortOrder() {
            if (this.sortOrder === 'asc') {
                this.sortOrder = 'desc';
            } else {
                this.sortOrder = 'asc';
            }
        },

        viewAllProjects(uid) {
            this.setActiveTab('projects');
            this.searchQuery = uid;
            // go to the top of the page
            window.scrollTo(0, 0);
        },

        viewDetailedProfile(user) {
            this.setActiveTab('projects', user);
            // go to the top of the page
            window.scrollTo(0, 0);
        },

        async backup() {
            console.log(this.users);
            
            alert('Exporting data and creatig a backup on the server...');
            const batch = writeBatch(db);
            this.users.forEach((user) => {
                const userRef = doc(db, 'backups', 'users', 'data', user.uid);
                batch.set(userRef, user);
            });

            batch.set(doc(db, 'backups', 'users'), {
                count: this.users.length,
                backupAt: new Date()
            });

            batch.commit().then(() => {
                alert('Backup created successfully');
            }).catch((error) => {
                alert('Error creating backup:', error);
                console.error('Error creating backup:', error);
            });

            // Write to a file and download it
            const backupData = {
                users: this.users,
                meta: {
                    count: this.users.length,
                    backupAt: new Date()
                }
            };
            const data = JSON.stringify(backupData);
            
            const blob = new Blob([data], { type: 'application/json' });
            const backupRef = storageRef(storage, 'backups/users.json');
            uploadBytes(backupRef, blob).then(() => {
                alert('Backup file created successfully');
                getDownloadURL(backupRef).then((url) => {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                        const link = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        link.href = url;
                        link.download = 'backup-file'; // Set the desired file name here
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                });
            }).catch((error) => {
                alert('Error creating backup file:', error);
                console.error('Error creating backup file:', error);
            });
        },
    },
    async mounted() {
        this.setActiveTab(this.active_tab);

        await this.getAllProjects();
    }
};
</script>

<style scoped lang="scss">
.credits_changer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    button {
        width: 64px;
        margin: 2px 4px;
    }
}

.control {
    &.end {
        margin-inline-end: 0;
    }
}

.toolbar {
    .select-dropdown {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .iconify {
            margin-inline-start: 8px;
            // margin-top: 8px;
            font-size: 30px;
        }
    }
}

.select-menu {
    margin: auto 8px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.section-title {
    margin-top: 5px;
    margin-bottom: 0px;
}

.pre-meta {
    width: 30% !important;
    h3 {
        max-width: 100% !important;
    }
}

.meta-left {
    width: 40% !important;
}

.meta-right {
    width: 30% !important;
}
</style>