<template>
    <div class="my-info-area">
        <div class="view-wrapper" style="min-height: 10vh;">
            <div class="page-content-wrapper">
                <div class="page-content is-relative">
                    <!-- <div class="page-title has-text-centered">
                        <div class="title-wrap">
                            <h1 class="title">
                                <span>My Info</span>
                            </h1>
                        </div>
                    </div> -->
                    <div class="personal-dashboard">
                        <div class="columns is-multiline">
                            <div class="column is-12">
                                <div class="dashboard-header">
                                    <div class="v-avatar is-xl">
                                        <img :src="avatar" alt="" class="avatar" />
                                    </div>
                                    <div class="user">
                                        <h3 class="title is-4 is-narrow is-bold">
                                            Welcome back,
                                            {{ userStatus }}
                                            {{ userFirstname }}
                                        </h3>
                                        <p class="light-text">It's really nice to see you again. You can check and modify your information below</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>  
        <VProfile v-if="user" :user="user"/>
    </div>
</template>

<script>
import VProfile from '../profile/VProfile.vue';
import { auth, db } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { ref } from 'vue';
import DashboardArea from '../dashboard/DashboardArea.vue';

export default {
    name: "MyInfoArea",
    components: {
        VProfile,
        DashboardArea
    },
    setup() {
        const user = ref(null);
        const userStatus = ref(null);
        const userFirstname = ref(null);
        const avatar = require('@/assets/images/dna3.jpg');

        onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const docRef = doc(db, 'users', authUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    user.value = docSnap.data();
                }
                user.value.createdAt = new Date(user.value.createdAt.seconds * 1000).toLocaleDateString();
                userStatus.value = user.value.status;
                userFirstname.value = user.value.firstname;
            }
        });
        return {
            user,
            userStatus,
            userFirstname,
            avatar
        };
    }
};
</script>

<style scoped>
.my-info-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}
</style>