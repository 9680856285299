export const USER_STATUS_NEW = "New";
export const USER_STATUS_WAITLIST = "In waiting list";
export const USER_STATUS_ACTIVE = "Active";
export const USER_STATUS_CNY_ADMIN = "Company Admin";
export const USER_STATUS_QL_ADMIN = "QL Admin";
export const USER_STATUS_LO_ADMIN = "LO Admin";

export const STATUS_LIST = [
  USER_STATUS_NEW,
  USER_STATUS_WAITLIST,
  USER_STATUS_ACTIVE,
  USER_STATUS_CNY_ADMIN,
  USER_STATUS_QL_ADMIN,
  USER_STATUS_LO_ADMIN,
];
