<template>
  <div>
      <Preloader v-if="isLoading" />
      <div class="view-wrapper">
          <div class="page-content-wrapper is-size-default">
              <div class="page-content is-relative">
                  <div class="page-title has-text-centered">
                      <div class="title-wrap">
                          <h1 class="title">
                              <span>User Profile</span>
                          </h1>
                      </div>
                  </div>
                  <form novalidate class="form-layout" @submit.prevent="submit">
                      <div class="form-outer">
                          <div class="form-header stuck-header">
                            <div class="form-header-inner">
                              <div class="left">
                                <h3>{{ isEditing ? 'Edit Profile' : 'Profile Details' }}</h3>
                              </div>
                              <div class="right">
                                <div class="buttons">
                                  <button type="button" class="button v-button is-primary is-raised" @click="toggleEdit">
                                    <span>{{ isEditing ? 'Cancel' : 'Edit' }}</span>
                                  </button>
                                  <button v-if="isEditing" type="button" class="button v-button is-primary is-raised" @click="saveProfile">
                                    <span>Save</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-body">
                              <div class="form-fieldset">
                                  <div class="fieldset-heading">
                                      <h4>User Infomation</h4>
                                      <p>You can modify your infomation.</p>
                                  </div>
                                  <div class="columns is-multiline">
                                      <div class="column is-12">
                                          <div class="field">
                                              <label class="label">First Name <span>*</span></label>
                                              <div class="control has-icon">
                                                  <input type="text" class="input" placeholder autocomplete="given-name" v-model="firstname" :disabled="!isEditing"/>
                                                  <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="column is-12">
                                          <div class="field">
                                              <label class="label">Last Name <span>*</span></label>
                                              <div class="control has-icon">
                                                  <input type="text" class="input" placeholder autocomplete="family-name" v-model="lastname" :disabled="!isEditing"/>
                                                  <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="column is-12">
                                        <div class="field">
                                          <label class="label">Job Level <span>*</span></label>
                                          <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                            <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing && toggleDropdown('jobLevel')">
                                              <div class="multiselect-placeholder" v-if="jobLevel === ''">Job Level</div>
                                              <div class="multiselect-single-label" v-else>
                                                <div class="multiselect-single-label-text">{{ jobLevel }}</div>
                                              </div>
                                              <div class="multiselect-caret">
                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                              </div>
                                            </div>
                                            <div class="multiselect-dropdown is-hidden" tabindex="1" id="jobLevel">
                                              <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                <div 
                                                  class="multiselect-option"
                                                  role="option"
                                                  v-for="job in jobLevels"    
                                                  :key="job"
                                                  @click.stop="handleSelectJobLevel(job)"
                                                  :class="{ 'is-selected': jobLevel === job }"
                                                >
                                                  <span>{{ job }}</span>
                                                </div>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="column is-6">
                                        <div class="field">
                                          <label class="label">Job Function <span>*</span></label>
                                          <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                            <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing && toggleDropdown('jobFunction')">
                                              <div class="multiselect-placeholder" v-if="jobFunction === ''">Job Function</div>
                                              <div class="multiselect-single-label" v-else>
                                                <div class="multiselect-single-label-text">{{ jobFunction }}</div>
                                              </div>
                                              <div class="multiselect-caret">
                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                              </div>
                                            </div>
                                            <div class="multiselect-dropdown is-hidden" tabindex="1" id="jobFunction">
                                              <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                <div 
                                                  class="multiselect-option"
                                                  role="option"
                                                  v-for="func in jobFunctions"    
                                                  :key="func"
                                                  @click.stop="handleSelectJobFunction(func)"
                                                  :class="{ 'is-selected': jobFunction === func }"
                                                >
                                                  <span>{{ func }}</span>
                                                </div>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="column is-6">
                                        <div class="field">
                                          <label class="label">Country/Region <span>*</span></label>
                                          <div class="multiselect" :class="{'is-disabled': !isEditing}">
                                            <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="isEditing && toggleDropdown('country')">
                                              <div class="multiselect-placeholder" v-if="country === ''">Country/Region</div>
                                              <div class="multiselect-single-label" v-else>
                                                <div class="multiselect-single-label-text">{{ country }}</div>
                                              </div>
                                              <div class="multiselect-caret">
                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                              </div>
                                            </div>
                                            <div class="multiselect-dropdown is-hidden" tabindex="1" id="country">
                                              <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                <div 
                                                  class="multiselect-option"
                                                  role="option"
                                                  v-for="c in countries"    
                                                  :key="c"
                                                  @click.stop="handleSelectCountry(c)"
                                                  :class="{ 'is-selected': country === c }"
                                                >
                                                  <span>{{ c }}</span>
                                                </div>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="column is-6">
                                          <div class="field">
                                              <label class="label">Work Phone Number<span>*</span></label>
                                              <div class="control has-icon">
                                                  <input type="text" class="input" placeholder autocomplete="tel" v-model="phone" :disabled="!isEditing"/>
                                                  <iconify-icon class="iconify form-icon" icon="lucide:phone"></iconify-icon>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="column is-12">
                                          <div class="field">
                                              <label class="label">Work Email <span>*</span></label>
                                              <div class="control has-icon">
                                                  <input type="email" class="input" placeholder autocomplete="email" inputmode="email" v-model="email" :disabled="!isEditing"/>
                                                  <iconify-icon class="iconify form-icon" icon="lucide:mail"></iconify-icon>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { accountRoles } from '../../services/accountRoles';
import { auth, db } from '@/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Preloader from '../layout/Preloader.vue';
import { jobLevels, jobFunctions, countries } from '../../services/jobInfo';

import { USER_STATUS_ACTIVE, USER_STATUS_WAITLIST } from '../dashboard/dashboardConstants';


export default {
  name: 'Profile',
  data() {
    return {
      firstname: '',
      lastname: '',
      jobLevel: '',
      jobFunction: '',
      country: '',
      phone: '',
      email: '',
      userProfile: null,
      isLoading: false,
      jobLevels,
      jobFunctions,
      countries,
      isEditing: false,
    };
  },
  components: {
    Preloader,
  },
  methods: {
    async fetchUserProfile(user) {
      this.isLoading = true;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          this.userProfile = userDoc.data();
          this.firstname = this.userProfile.firstname || '';
          this.lastname = this.userProfile.lastname || '';
          this.jobLevel = this.userProfile.jobLevel || '';
          this.jobFunction = this.userProfile.jobFunction || '';
          this.country = this.userProfile.country || '';
          this.phone = this.userProfile.phone || '';
          this.email = this.userProfile.email || '';
        } else {
          console.error('No such document!');
        }
      } else {
        this.userProfile = null;
        console.error('You are not logged in. Please log in to access this page.');
      }
      this.isLoading = false;
    },

    toggleDropdown(id) {
      const dropdown = document.getElementById(id);
      dropdown.classList.toggle('is-hidden');
    },

    handleSelectJobLevel(value) {
      this.jobLevel = value;
      this.toggleDropdown('jobLevel');
    },

    handleSelectJobFunction(value) {
      this.jobFunction = value;
      this.toggleDropdown('jobFunction');
    },

    handleSelectCountry(value) {
      this.country = value;
      this.toggleDropdown('country');
    },

    handleClickOutside(event) {
      const dropdowns = document.querySelectorAll('.multiselect-dropdown');
      dropdowns.forEach((dropdown) => {
        if (!dropdown.contains(event.target)) {
          dropdown.classList.add('is-hidden');
        }
      });
    },

    async saveProfile() {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        try {
          await setDoc(userRef, {
            firstname: this.firstname,
            lastname: this.lastname,
            jobLevel: this.jobLevel,
            jobFunction: this.jobFunction,
            country: this.country,
            phone: this.phone,
            email: this.email,
          }, { merge: true });
          this.isEditing = false;
          alert('Information successfully updated'); 
        } catch (error) {
          console.error('Error saving profile:', error);
          alert('An error occurred while saving the profile. Please try again.');
        }
      } else {
        alert('Please login to save the profile');
        this.$router.push('/login');
      }
    },

    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.fetchUserProfile(user);
      } else {
        this.userProfile = null;
      }
    });
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  beforeRouteLeave(to, from, next) {
    document.removeEventListener('click', this.handleClickOutside);
    next();
  },
};
</script>

<style scoped lang="scss">
.input:disabled {
  border: none; /* Hide the border */
  background-color: #f0f0f0; /* Example background color */
  color: #a0a0a0; /* Example text color */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.multiselect {
  &.is-disabled {
    border: none;
    cursor: not-allowed;
    background-color: #f0f0f0;

    .multiselect-single-label-text {
      color: #a0a0a0;
    }

    * {
      cursor: not-allowed;
    }
  }
}
</style>