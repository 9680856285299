<template>
  <div>
    <QLAdminArea />
  </div>
</template>

<script>
import QLAdminArea from '../admin/QLAdminArea.vue';

export default {
  name: 'QLAdminPage',
  components: {
    QLAdminArea,
  },
};
</script>