<template>
    <div class="view-wrapper has-top-nav">
        <div class="page-content-wrapper">
            <div class="page-content">
                <div class="section-title">
                    <h2>Waiting List</h2>
                    <h4 v-if="active_tab === 'all'">In total, {{ shownUsers.length }} accounts have already joined in the waiting list.</h4>
                </div>
                <div>
                    <div class="list-view-toolbar">
                        <div class="tabs-inner">
                            <div class="tabs">
                                <ul>
                                    <li id="wl-tab-all" class="is-active" @click="setActiveTab('all')">
                                        <a  role="button" >
                                            <span>All</span>
                                        </a>
                                    </li>
                                    <li id="wl-tab-details" @click="setActiveTab('details')">
                                        <a role="button">
                                            <span>Details</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="control has-icon is-flex" v-if="active_tab === 'all'">
                            <div>
                                <input type="text" class="input" placeholder="Search..." v-model="searchQuery">
                                <iconify-icon class="form-icon iconify" icon="lucide:search" />
                            </div>
                            <div class="toolbar">
                                <div 
                                    class="select-dropdown" 
                                >
                                    <div class="sort-buttons" @click="changeSortOrder()">
                                        <iconify-icon class="iconify" icon="octicon:sort-asc-16" v-if="sortOrder==='asc'"/>
                                        <iconify-icon class="iconify" icon="octicon:sort-desc-16" v-if="sortOrder==='desc'"/>
                                    </div>
                                    <select v-model="sortCriteria" class="select-menu">
                                        <option value="submitAt">Submission Date</option>
                                        <option value="companyName">Company Name</option>
                                        <option value="email">Email</option>
                                        <option value="firstname">First Name</option>
                                        <option value="credits">Credits</option>
                                        <option value="status">Status</option>
                                        <option value="uid">UID</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="buttons" v-if="active_tab === 'all'">
                            <button class="button v-button is-primary is-raised" @click.prevent="backup()">Export</button>
                        </div>
                    </div>
                    <div class="list-view" v-if="active_tab==='all'">
                        <div class="list-view-inner">
                            <div class="list-view-item" v-for="(user, index) in shownUsers" :key="user.uid">
                                <div class="list-view-item-inner">
                                        <div class="pre-meta">
                                            <h3>{{ user.companyName }}</h3>
                                            <h4>{{ user.email }}</h4>
                                        </div>
                                        <div class="meta-left">
                                            <h3>{{ user.firstName }} {{ user.lastName }}</h3>
                                            <span>UID: <b>{{ user.uid }}</b></span>
                                            <span>Current Status: <b>{{ user.status }}</b></span>
                                        </div>
                                        <div class="meta-right">
                                            <div class="info-list">
                                                <span>Submitted Date: <b>{{ user.submitAt }}</b></span>
                                                <span>Credits: <b>{{ user.credits }}</b></span>
                                            </div>
                                            <div class="buttons" v-if="selectUserID !== user.uid">
                                                <button class="button v-button is-light is-rounded" @click="selectUserID=user.uid">Change Credits</button>
                                                <a class="button v-button is-primary is-raised" @click="viewDetailedProfile(user)">View Profile</a>
                                            </div>

                                            <div class="credits_changer" v-else>
                                                <section>
                                                    <input type="number" v-model="credits_change" placeholder="Enter number of credits" class="input">
                                                </section>
                                                <footer>
                                                    <button class="button v-button is-primary is-raised" @click="creditChanger(user, 'add', credits_change)">Add</button>
                                                    <button class="button v-button is-light is-raised is-bold" @click="creditChanger(user, 'remove', credits_change)">Remove</button>
                                                    <button class="button v-button is-raised" @click="selectUserID = null">Cancel</button>
                                                </footer>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="detail-view" v-else>
                        <VProfile :user="currentUserDetail" :key="currentUserDetail.uid"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from "vue";
import { db, storage } from "@/firebase";
import { doc, getDocs, collection, updateDoc, writeBatch, getDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL  } from "firebase/storage";
import VProfile from "../profile/VProfile.vue";
import { STATUS_LIST } from "../dashboard/dashboardConstants";

export default {
    name: "WaitlistInfoArea",
    data() {
        return {
            active_tab: reactive('all'),
            users: reactive([]),
            selectUserID: reactive(null),
            credits_change: 0,
            sortDropdownActive: reactive(false),
            sortOrder: reactive('desc'),
            searchQuery: '',
            sortCriteria: 'submitAt',
            currentUserDetail: null,
            cachedShownUsers: [],
        };
    },
    components: {
        VProfile
    },
    computed: {
        shownUsers() {
            return this.cachedShownUsers;
        }
    },
    watch: {
        users: 'updateShownUsers',
        searchQuery: 'updateShownUsers',
        sortOrder: 'updateShownUsers',
        sortCriteria: 'updateShownUsers',
    },
    methods: {
        updateShownUsers(){
            let filteredUsers;
            if (!this.searchQuery) {
                filteredUsers = this.users;
            }
            else {
                filteredUsers = this.users.filter((user) => {
                    if (user.companyName.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        return true;
                    }
                    if (user.email.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        return true;
                    }
                    if (user.firstname && user.firstname.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        return true;
                    }
                    if (user.lastname && user.lastname.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        return true;
                    }
                    if (user.status.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        return true;
                    }
                    if (user.uid.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
            }

            let sortedUsers;
            if (this.sortCriteria === 'submitAt') {
                sortedUsers = filteredUsers.sort((a, b) => new Date(a.submitAt) - new Date(b.submitAt));
            }
            else if (this.sortCriteria === 'companyName' || this.sortCriteria === 'email' || this.sortCriteria === 'firstname' || this.sortCriteria === 'uid') {
                sortedUsers = filteredUsers.sort((a, b) => {
                    return a[this.sortCriteria].localeCompare(b[this.sortCriteria]);
                });
            }
            else if (this.sortCriteria === 'credits') {
                sortedUsers = filteredUsers.sort((a, b) => a.credits - b.credits);
            }
            else if (this.sortCriteria === 'status') {
                const statusList = STATUS_LIST;
                sortedUsers = filteredUsers.sort((a, b) => {
                    return statusList.indexOf(a.status) - statusList.indexOf(b.status);
                });
            }
            if (this.sortOrder === 'asc') {
                this.cachedShownUsers = sortedUsers;
            } else {
                this.cachedShownUsers = sortedUsers.reverse();
            }
        },

        setActiveTab(tab, user=null) {
            this.active_tab = tab;
            const tabs = document.querySelectorAll('.tabs ul li');
            tabs.forEach((tab) => {
                tab.classList.remove('is-active');
            });
            document.getElementById(`wl-tab-${tab}`).classList.add('is-active');
            if (tab === 'all') {
                this.getAllUsersFrom('waitlist');
            }
            else if (tab === 'details') {
                if (user) {
                    this.currentUserDetail = user;
                }
                else {
                    this.currentUserDetail = this.shownUsers[0];
                }
            }
        },

        async getAllUsersFrom(collectionPath) {
            const querySnapshot = await getDocs(collection(db, collectionPath));
            const users = [];

            const userPromises = querySnapshot.docs.map(async (document) => {
                let user = document.data();
                // check whether there are some fields that are timestamps
                if (user.submitAt) {
                    user.submitAt = user.submitAt.toDate().toLocaleDateString();
                }
                if (user.createdAt) {
                        user.createdAt = user.createdAt.toDate().toLocaleDateString();
                    }
                const userRef = doc(db, 'users', document.id);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    user = { ...user, ...userData };
                }
                users.push(user);
            });

            await Promise.all(userPromises);
            this.users = users;

            this.updateShownUsers();
        },
        
        changeSortOrder() {
            if (this.sortOrder === 'asc') {
                this.sortOrder = 'desc';
            } else {
                this.sortOrder = 'asc';
            }
        },

        async creditChanger(user, action, credits) {
            const userRef = doc(db, 'users', user.uid);
            if (action === 'add') {
                let newCredits = user.credits + credits;
                updateDoc(userRef, {
                    credits: newCredits
                }).then(() => {
                    alert('Credits added successfully to ' + user.email);
                    this.selectUserID = null;
                }).catch((error) => {
                    alert('Error adding credits:', error);
                    console.error('Error adding credits:', error);
                });
            } else if (action === 'remove') {
                let newCredits = user.credits - credits;
                if (newCredits < 0) {
                    newCredits = 0;
                }
                updateDoc(userRef, {
                    credits: newCredits
                }).then(() => {
                    alert('Credits removed successfully from ' + user.email);
                    this.selectUserID = null;
                }).catch((error) => {
                    alert('Error removing credits:', error);
                    console.error('Error removing credits:', error);
                });
            }

            this.getAllUsersFrom('waitlist');
        },

        viewDetailedProfile(user) {
            this.setActiveTab('details', user);
            // go to the top of the page
            window.scrollTo(0, 0);
        },

        async backup() {
            console.log(this.users);
            
            alert('Exporting data and creatig a backup on the server...');
            const batch = writeBatch(db);
            this.users.forEach((user) => {
                const userRef = doc(db, 'backups', 'users', 'data', user.uid);
                batch.set(userRef, user);
            });

            batch.set(doc(db, 'backups', 'users'), {
                count: this.users.length,
                backupAt: new Date()
            });

            batch.commit().then(() => {
                alert('Backup created successfully');
            }).catch((error) => {
                alert('Error creating backup:', error);
                console.error('Error creating backup:', error);
            });

            // Write to a file and download it
            const backupData = {
                users: this.users,
                meta: {
                    count: this.users.length,
                    backupAt: new Date()
                }
            };
            const data = JSON.stringify(backupData);
            
            const blob = new Blob([data], { type: 'application/json' });
            const backupRef = storageRef(storage, 'backups/users.json');
            uploadBytes(backupRef, blob).then(() => {
                alert('Backup file created successfully');
                getDownloadURL(backupRef).then((url) => {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                        const link = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        link.href = url;
                        link.download = 'backup-file'; // Set the desired file name here
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                });
            }).catch((error) => {
                alert('Error creating backup file:', error);
                console.error('Error creating backup file:', error);
            });
        },
    },
    async mounted() {
        this.setActiveTab(this.active_tab);
        await this.getAllUsersFrom('waitlist');
    },
};
</script>

<style scoped lang="scss">
.credits_changer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    button {
        width: 64px;
        margin: 2px 4px;
    }
}

.control {
    &.end {
        margin-inline-end: 0;
    }
}

.toolbar {
    .select-dropdown {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .iconify {
            margin-inline-start: 8px;
            // margin-top: 8px;
            font-size: 30px;
        }
    }
}

.select-menu {
    margin: auto 8px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.section-title {
    margin-top: 5px;
    margin-bottom: 0px;
}
</style>